/* .kendo-pdf-document div {
 color: res !important;
} */
.pdf {
    font-family: "Arial" !important;
  }.page-break {
    page-break-before: always; /* Forces a page break before this element */
    /* or */
    page-break-after: always; /* Forces a page break after this element */
  }
  .avoid-break {
    page-break-inside: avoid;
  }